import { createVueApp } from '@/vue_components/create_vue_app'
import MessagesConfiguration from '@/vue_apps/app_configuration/messages/MessagesConfiguration.vue'

$document.on('rez/messages/configurations', () => {
  const el = '#vue_messages_configuration'

  createVueApp({
    el,
    name: 'MessagesConfigurationRoot',
    render: (h) => h(MessagesConfiguration),
  })
})
