import { createVueApp } from '@/vue_components/create_vue_app'
import FrontBook from '@/vue_present/_FrontBook/FrontBook'

$document.on('rez/front_book', () => {
  const el = '#vue_front_book'

  createVueApp({
    el,
    name: 'FrontBookRoot',
    render: (h) => h(FrontBook),
  })
})
