import { createVueApp } from '@/vue_components/create_vue_app'
import EntryPacksAppRoot from '@/vue_apps/catalogs_root/EntryPacks/EntryPacksAppRoot.vue'

let vueApp = null

const createEntryPacksApp = (el = '#vue_entry_packs_catalog_root') => {
  if (!document.querySelector(el)) { return }
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el,
    name: 'EntryPacksAppRoot',
    render: (h) => h(EntryPacksAppRoot),
  })
}

$document.on(
  'rez/entry_packs/index rez/entry_packs/edit rez/entry_packs/new',
  () => createEntryPacksApp()
)
