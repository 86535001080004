<template>
  <m-panel
    class="communication-messages"
    :title="panelTitle"
    icon="messages"
  >
    <si-generator
      :items="messagesList.data"
      :table-schema="tableSchema"
      :search-input-placeholder="t('full_name_or_phone')"
      :current-page="messagesList.currentPage"
      :total-pages="messagesList.totalPages"
      :search-value.sync="messagesList.searchQuery"
      :is-filtered="isFiltered"
      :loading="loading"
      :use-search="useSearch"
      @update:currentPage="messagesList.setPage($event)"
      @onResetFilters="messagesList.resetFilters()"
    >
      <template #control-filters>
        <messages-filters
          v-bind="$props"
          :filters="filters"
          @setFilter="messagesList.setFilterValue($event.field, $event.value)"
          @refresh="messagesList.refreshFilters()"
          @reset="messagesList.resetFilters()"
          @filtersUpdated="fetchList"
        />
      </template>

      <template
        v-for="message in messagesList.data"
        #tr-clients="{ item: message }"
      >
        <div
          :key="message.id"
          class="col col__clients"
        >
          <div
            v-for="client in message.clients"
            :key="`clientId:${client.id}`"
            :class="{ 'deleted': client.deletedAt }"
          >
            <a :href="$routes.client_path(client.id)">
              {{ client.shortName }}
            </a>
          </div>
        </div>
      </template>

      <template
        v-for="message in messagesList.data"
        #tr-phone="{ item: message }"
      >
        <div
          v-if="message.clients.length"
          :key="`clientPhone:${message.clients[0].id}:${message.phone}`"
          class="col col__phone"
        >
          <span
            class="phone_number"
            :data-phone="message.phone"
            :data-client-id="phoneDataClientId(message.clients)"
          >
            {{ message.phone }}
          </span>
        </div>
      </template>
    </si-generator>
  </m-panel>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import SiGenerator from '@/vue_components/sort_items/SiGenerator/SiGenerator.vue'
import MessagesFilters from '@/vue_apps/CommunicationModule/Messages/components/MessagesFilters.vue'
import { MessagesList } from '@/vue_apps/CommunicationModule/Messages/store/MessagesList'
import { messageAppDrillingProps } from '@/vue_apps/CommunicationModule/Messages/props/MessageAppDrillingProps'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'

export default {
  name: 'MessagesApp',
  components: { MessagesFilters, SiGenerator, MPanel },

  mixins: [SpinnerHolder],

  props: {
    ...messageAppDrillingProps,

    defaultFilters: { type: Object, required: true },
    tableSchema: { type: Object, required: true },
    presenter: { type: Object, required: true },
  },

  data () {
    return {
      messagesList: new MessagesList(
        this.defaultFilters,
        this.presenter,
        this.defaultFilters.__cacheKey
      ),
    }
  },

  computed: {
    filters () { return this.messagesList.filters },
    isFiltered () { return this.messagesList.isFiltered },

    messagesCount () { return this.messagesList.totalItems },

    panelTitle () {
      const title = t('sms.messages')
      if (!this.messagesCount) { return title }

      return `${title} (${this.messagesCount})`
    },
  },

  created () {
    this.fetchList()
  },

  methods: {
    async fetchList () {
      await this.withSpinner(this.messagesList.fetchAll(), undefined, false)
    },

    phoneDataClientId (clients) {
      return clients.length === 1 ? clients[0].id : undefined
    },
  },
}
</script>
